.icons {
  display: flex;
  width: 100%;
  height: 100%;
}

.icons.align-right {
  justify-content: space-between;
}
.icons.align-right :first-child {
  overflow: hidden;
  text-overflow: ellipsis;
}

.icons .code {
  font-family: inherit;
  font-size: 0.75rem;
}

.icons .wrapper {
  padding-right: 0.2rem;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.icon {
  font-size: 1rem;
}
