/**
 * Please use these styles when working with ag grid
 */

@use "~ag-grid-community/styles" as ag;

@import "app/assets/app.scss";

@include ag.grid-styles(
  (
    theme: material,
    material-primary-color: $mdc-theme-primary,
    material-accent-color: $mdc-theme-secondary,
    cell-horizontal-padding: 10px,
    font-family: $mdc-typography-font-family,
  )
);

.ag-theme-material {
  --ag-border-color: var(--mdc-theme-gray-100);
  --ag-row-hover-color: var(--mdc-theme-gray-100);

  .ag-checkbox-input-wrapper {
    font-size: 1rem !important;
  }

  .ag-pinned-left-cols-container {
    border-right: 1px solid $mdc-theme-gray-100;
  }

  // TBD: any ideas ? - pinned has missing paddings so avatar its pulled to the left side
  .ag-cell.center {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .ag-react-container,
  .ag-popup {
    pointer-events: none;
  }
}
