reach-portal {
  [data-reach-dialog-overlay] {
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;

    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    @keyframes fadeout {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    &.animate-exited {
      display: none;
    }

    &.animate-exiting {
      animation: fadeout 0.25s;
      opacity: 0;
    }

    &.animate-entering {
      animation: fadein 0.25s;
      opacity: 1;
    }

    [data-reach-dialog-content] {
      padding: 0;
      margin: 0;
    }

    &.no-title {
      [data-reach-dialog-content] {
        padding-top: 1rem;
      }
    }

    [data-reach-dialog-content] {
      width: auto;
    }
    &.set-width {
      [data-reach-dialog-content] {
        /* default - mobile */
        width: 100vw;

        /* more than mobile */
        @media (min-width: 25rem) {
          min-width: 17.5rem;
          width: 33.33vw;
        }
      }
    }
  }
}
