/**
 * Styles for tippy.js/popper.js
 *
 * We could use styled components, but we are going crazy with tooltips in some places.
 * Therefore, this is faster in places like Datagrids where it would feel bad using styled components for few rules.
 */

@import "~tippy.js/dist/tippy";
@import "~tippy.js/animations/scale-subtle";
@import "~tippy.js/themes/material";

.tippy-box[data-theme~="material"] {
  font-size: 0.675rem;
  font-weight: normal;
}

.tippy-box[data-theme~="light"] {
  .tippy-content {
    padding: 0;
  }
  .tippy-arrow {
    color: white;
  }
}

@media print {
  .tippy-box {
    display: none;
  }
}
