.avatar {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}

.avatar-placeholder {
  height: 2rem;
  width: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  opacity: 0.6;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
