@import "app/assets/icomoon/variables.scss";

.Toastify__toast-container.material-redesign {
  width: auto;
  min-width: 20rem;
  max-width: 26rem;

  .Toastify__toast {
    box-shadow:
      0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12);
    background: #fff;
    border-radius: 0.25rem;
    padding: 0.875rem 1rem;

    color: $mdc-theme-secondary;
    font-family: inherit;
    font-size: 0.875rem;
    line-height: 1.25rem;

    .mdc-icon-button::before {
      --mdc-ripple-fg-size: 100%;
      --mdc-ripple-fg-scale: 1;
      --mdc-ripple-left: 0;
      --mdc-ripple-top: 0;
    }

    .Toastify__toast-body {
      display: flex;
      align-items: center;

      &::before {
        display: inline-block;
        font-family: "Material Icons";
        font-size: 1.5rem;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-height: 1;
        text-transform: none;
        white-space: nowrap;
        word-wrap: normal;
        -webkit-font-feature-settings: "liga";
        font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
        margin-right: 1.25rem;
      }

      &.loadingToast {
        margin-left: -0.5rem;
        padding: 0;

        .material-icons {
          margin: 0;
          transform: scale(0.75);
          margin-right: 1.25rem;
        }
      }
    }

    .Toastify__toast-body::before {
      font-family: "icomoon";
    }

    &.Toastify__toast--error .Toastify__toast-body::before {
      color: $mdc-theme-error;
      content: $icon-error;
    }

    &.Toastify__toast--info .Toastify__toast-body::before {
      color: $mdc-theme-info;
      content: $icon-info;
    }

    &.Toastify__toast--warning .Toastify__toast-body::before {
      color: $mdc-theme-warning;
      content: $icon-warning;
    }

    &.Toastify__toast--success .Toastify__toast-body::before {
      color: $mdc-theme-success;
      content: $icon-check_circle;
    }
  }
}
