.checkbox {
  font-size: 1.35rem;
  /* Small hack to align header checkbox with with ag-grid checkboxes we don't render ourselves */
  margin-left: -3px;

  /* Reset button style */
  cursor: default !important;
  padding: 0;
  border: none;
  background: transparent;
  overflow: hidden;
}

.checkbox > span[aria-hidden="true"] {
  visibility: hidden;
}
