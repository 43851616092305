$mdc-theme-primary: #0080f0 !default;
$mdc-theme-secondary: #2a2a2a !default;
$mdc-theme-background: #f5f5f5 !default;
$mdc-theme-error: #f44336 !default;
$mdc-theme-warning: #ff9800 !default;
$mdc-theme-success: #4caf50 !default;
$mdc-theme-disabled: #9e9e9e !default;

@import "./reset.scss";

@import "~@material/react-button/index.scss";
@import "~@material/react-layout-grid/index.scss";
@import "~@material/react-card/index.scss";
@import "~@material/react-list/index.scss";
@import "~@material/react-chips/index.scss";
@import "~@material/react-switch/index.scss";
@import "~@material/react-tab-bar/index.scss";
@import "~@material/react-tab-scroller/index.scss";
@import "~@material/react-tab/index.scss";
@import "~@material/react-tab-indicator/index.scss";
@import "~@material/react-select/index.scss";
@import "~@material/react-checkbox/index.scss";
@import "~@material/react-text-field/index.scss";
@import "~@material/react-radio/index.scss";
@import "~@material/react-icon-button/index.scss";
@import "~@material/react-dialog/index.scss";
@import "~@material/react-linear-progress/index.scss";

/**
  * Global styles
  */

html.no-focus-outline {
  * {
    outline: none !important;
  }
}

/**
  * Third party libraries overrides
  */

.material-icons {
  overflow: hidden;
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
  line-height: 1;
  position: relative;
  display: flex;
}

.material-icons::before {
  position: absolute;
  text-align: center;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.mdc-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

/**
 * There is a problem with the height in certain very specific situations
 * As mdc-select is inline-flex
 */
.mdc-select {
  display: flex;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before,
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::after {
  background-color: lighten($mdc-theme-primary, 30%); // #ffce98;
}

.mdc-list-item--selected,
.mdc-list-item--activated {
  color: darken($mdc-theme-primary, 10%);
}

.mdc-switch.mdc-switch--checked {
  .mdc-switch__thumb,
  .mdc-switch__track {
    border-color: $mdc-theme-success;
    background-color: $mdc-theme-success;
  }
}

@import "./toast-redesign";
@import "../components/Modal/global";
@import "../components/Tooltip/global";
