.circle-letters-wrapper {
  display: flex;
  align-items: center;
}

.circle-letters-right-title {
  padding-left: 0.625rem;
}

.circle-letters {
  font-size: 0.75rem;
  line-height: 1;
  border-radius: 50%;
  width: 1.875rem;
  height: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
